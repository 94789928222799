import React from 'react'
//import Particles from 'react-particles-js'
import { Element } from 'react-scroll'
import Layout from './../components/layouts/default'
//import { Typography } from './../components/ui'

import Teaser from './../scenes/teaser'
import Pillars from './../scenes/pillars'
import Clients from './../scenes/clients'
//import Prices from './../scenes/prices'
import Featureds from './../scenes/featureds'
import Demo from './../scenes/demo'

const Index = () => (

    <Layout title="Intrasites - Mucho más que un feed de noticias" containerType="all" headerPosition="transparent">

      <Teaser/>

      <Element name="comunication">
        <Pillars/>
        <Clients/>
      </Element>

      {/* <Element name="pricing">
        <Prices/>
      </Element> */}

      <Featureds/>

      <Demo/>

    </Layout>

)

export default Index
