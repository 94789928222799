import React from 'react'
import { translate, Trans } from 'react-i18next'
import { Container, Row, Col } from './../../static/theme'
import { Typography } from './../../components/ui'

import Request from './../../components/shared/request'

const Teaser = ({ t }) => (

  <div className="ds-teaser ds--teaser--header__transparent">
    <Container>

      <section className="ds-section_particles"></section>

      <section className="ds-section_teaser">

        <Typography component="h1" classes="ds-section_teaserTextPrimary" dangerouslySetInnerHTML={{ __html: t('translation:teaser.title') }}/>

        <div className="ds-section_teaserOptions">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="primary" weight="bold" classes="ds-section_teaserOptions-title">
                { t('teaser.subtitle') }
              </Typography>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="ds-section_teaserOptions-divider"></div>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography classes="ds-section_teaserOptions-paragraph">
                { t('teaser.paragraph') }
              </Typography>
            </Col>
            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography variant="white" weight="light" classes="ds-section_teaserOptions-subtitle" dangerouslySetInnerHTML={{ __html: t('translation:teaser.block_01') }}/>
            </Col>
            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography variant="white" weight="light" classes="ds-section_teaserOptions-subtitle" dangerouslySetInnerHTML={{ __html: t('translation:teaser.block_02') }}/>
            </Col>
            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography variant="white" weight="light" classes="ds-section_teaserOptions-subtitle" dangerouslySetInnerHTML={{ __html: t('translation:teaser.block_03') }} />
            </Col>
            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography variant="white" weight="light" classes="ds-section_teaserOptions-subtitle" dangerouslySetInnerHTML={{ __html: t('translation:teaser.block_04') }}/>
            </Col>
            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography variant="white" weight="light" classes="ds-section_teaserOptions-subtitle" dangerouslySetInnerHTML={{ __html: t('translation:teaser.block_05') }}/>
            </Col>
            <Col xl={4} lg={4} md={6} sm={6} xs={12}>
              <Typography variant="white" weight="light" classes="ds-section_teaserOptions-subtitle" dangerouslySetInnerHTML={{ __html: t('translation:teaser.block_06') }}/>
            </Col>
          </Row>
        </div>


        <div className="ds-section_teaserCall">
          {/* <Request/> */}
          <Typography component="a" href="mailto:info@intrasites.com" variant="primary" weight="bold" classes="ds-section_teaserOptions-title">
            info@intrasites.com
          </Typography>
        </div>

        <div className="ds-section_teaserCaseStudy">
          <Typography component="a" variant="primary" weight="bold" target="_blank" href="../../static/docs/caso-de-exito-intrasites.pdf" classes="ds-section_teaserCaseStudy-link">
            {t('teaser.case_study')}
          </Typography>
        </div>

      </section>
    </Container>
  </div>

)

export default translate('translation')(Teaser)
